<template>
  <div id="PendingDTask">
    <div class="vx-row">
      <feather-icon
        v-if="EnglishLan"
        @click="back()"
        icon="ChevronLeftIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <feather-icon
        v-else
        @click="back()"
        icon="ChevronRightIcon"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <p class="text-xl">
        {{ $t("PendingTaskDoctor") }}
      </p>
    </div>
    <h3 v-if="Model.length == 0" class="w-full sm:w-auto text-center text-primary mt-5">
      {{ $t("NoDataToshow") }}
    </h3>

    <div v-for="item in Model" :key="item.ID" class="m-5">
      <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-col vs-type="flex" vs-align="center" vs-w="3" vs-sm="6">
          <div>
          
            <imageLazy
              :imageStyle="{ height: '40px', width: '40px' }"
              :imageClass="'rounded-full'"
              :src="baseURL + item.PatientImage"
              placeHolderType="patient"
            />
          </div>
          <div class="ml-5 mr-5">
            <h3>
              {{ item.PatientName }}
            </h3>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-align="center" vs-w="3" vs-sm="6">
          <div class="mb-2 mt-3">
            <!-- if patient has Reservation Session-->

            <div
              v-if="item.CategoryID == 1"
              @click="
                navigateToPage('/doctor/DoctorSessionDetails/' + item.ReservedSessionID)
              "
            >
              <u class="product-name font-medium">
                {{ $t("hasReservationSessionon") }} {{ item.Date.split("T")[0] }}
                {{ item.Date.split("T")[1] }}
              </u>
            </div>

            <div
              v-if="item.CategoryID == 6"
              @click="
                navigateToPage('/doctor/DoctorSessionDetails/' + item.ReservedSessionID)
              "
            >
              <u class="product-name font-medium">
                {{ $t("PleaseAddPrescription") }}
              </u>
            </div>

            <!-- if patient has Reservation Surgeries-->
            <div
              v-if="item.CategoryID == 2"
              @click="
                navigateToPage(
                  '/TreatmentsProfile/' +
                    item.HospitalSurgeryID +
                    '/0/' +
                    item.ReservedSurgeryID
                )
              "
            >
              <u class="product-name font-medium">
                {{ $t("hasReservationSurgeries") }}
              </u>
            </div>

            <!-- if patient has History Analysis-->
            <div
              v-else-if="item.CategoryID == 3"
              @click="
                item.ReservedSurgeryID
                  ? navigateToPage(
                      '/TreatmentsProfile/' +
                        item.HospitalSurgeryID +
                        '/0/' +
                        item.ReservedSurgeryID
                    )
                  : navigateToPage(
                      '/doctor/DoctorSessionDetails/' + item.ReservedSessionID
                    )
              "
            >
              <u class="product-name font-medium">
                {{ $t("downloadNote") }} {{ item.ReportTypeName }}
                {{ $t("Documentdoesntdownloadyet") }}
              </u>
            </div>

            <!-- if patient has History Report-->
            <div
              v-else-if="item.CategoryID == 4"
              @click="
                item.ReservedSurgeryID
                  ? navigateToPage(
                      '/TreatmentsProfile/' +
                        item.HospitalSurgeryID +
                        '/0/' +
                        item.ReservedSurgeryID
                    )
                  : navigateToPage(
                      '/doctor/DoctorSessionDetails/' + item.ReservedSessionID
                    )
              "
            >
              <u class="product-name font-medium">
                {{ $t("downloadNote") }} {{ item.ReportTypeName }}
                {{ $t("Documentdoesntdownloadyet") }}
              </u>
            </div>

            <!-- if patient has History Scan-->
            <div
              v-else-if="item.CategoryID == 5"
              @click="
                item.ReservedSurgeryID
                  ? navigateToPage(
                      '/TreatmentsProfile/' +
                        item.HospitalSurgeryID +
                        '/0/' +
                        item.ReservedSurgeryID
                    )
                  : navigateToPage(
                      '/doctor/DoctorSessionDetails/' + item.ReservedSessionID
                    )
              "
            >
              <u class="product-name font-medium">
                {{ $t("downloadNote") }} {{ item.ReportTypeName }}
                {{ $t("Documentdoesntdownloadyet") }}
              </u>
            </div>
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-col class="borderClass mt-0 pt-0" vs-w="6" vs-sm="12"></vs-col>
      </vs-row>
    </div>
  </div>
</template>
<script>
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";

export default {
  components: {
    imageLazy,
  },
  data() {
    return {
      EnglishLan: false,
      baseURL: domain,
      Model: [],
    };
  },
  computed: {
    patientReservation() {
      return this.$store.state.DoctorList.pendingTaskList;
    },
  },
  methods: {
    navigateToPage(page) {
      this.$router.push(page);
    },
    back() {
      this.$router.go(-1);
    },
    searchData() {
      debugger;
      if (this.$route.params.ID) {
        var id = this.$route.params.ID;
        this.$store
          .dispatch("DoctorList/GetPendingDoctorTaskList", id)
          .then((res) => {
            if (res.status == 200) {
              debugger;
              this.Model = res.data.Data;
              if (this.Model == null || this.Model.length == 0) {
                this.$vs.notify({
                  title: this.$t("NoData"),
                  text: this.$t("NoDataToshow"),
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "warning",
                });
              }
            }
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
      var x = this.Model.length;
      debugger;
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" || localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    this.searchData();
  },
};
</script>
<style>
.linkClass {
  text-decoration: underline;
  font: normal normal normal 15px/22px Futura PT;
  letter-spacing: 0px;

  text-transform: capitalize;
  opacity: 1;
}
.taskDoctor {
  background-color: transparent;
}
.borderClass {
  width: 100%;
  height: 2px;
  border: 1px solid #e7e7e7;
  opacity: 1;
}
#PendingDTask .vs-button {
  display: inline;
  background-color: transparent !important;
  color: gray;
  width: 5rem !important;
}
</style>
